.progress-wrapper {
  margin-bottom: 20px;
}

.progress-bar-and-buttons {
  display: flex;
  align-items: center;
}

.progress-container {
  flex-grow: 1;
  background-color: #f3f3f3; /* Light background for better contrast */
  border-radius: 12px; /* Rounded corners */
  overflow: hidden;
  height: 25px; /* Adjust the height as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border: 1px solid #ccc; /* Light border for definition */
  position: relative;
  margin-right: 10px; /* Space between progress bar and icons */
}

.progress {
  height: 100%;
  background-color: rgb(3, 27, 78); /* Dark blue color for the progress bar */
  border-radius: 12px 0 0 12px; /* Match the container's border radius */
  transition: width 0.4s ease-in-out; /* Smooth transition for width changes */
  position: relative;
}

.progress-tooltip {
  display: none;
  position: absolute;
  top: -30px; /* Position above the progress bar */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1; /* Ensure the tooltip is above other elements */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a shadow for better visibility */
}

.progress-wrapper:hover .progress-tooltip {
  display: block;
}

.icon-buttons {
  display: flex;
  gap: 10px;
}

.icon-button {
  background-color: rgb(3, 27, 78);
  color: white;
  border-radius: 50%;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}