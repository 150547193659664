.tracking-container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    /* Add some space between the forms */
}

.win-loss-form {
    flex: 1;
    /* Ensure the forms take equal space */
}

.tracking-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .tracking-container {
    margin-top: 20px;
  }