.win-loss-list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.win-list,
.loss-list {
    width: 45%;
}

.win-item,
.loss-item {
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    font-weight: bold;
    position: relative;
}

.win-item {
    background-color: #d4edda;
    color: #155724;
}

.loss-item {
    background-color: #f8d7da;
    color: #721c24;
}

.delete-button {
    background: none;
    border: none;
    color: rgb(3, 27, 78);
    ;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.delete-button:hover {
    color: darkred;
}