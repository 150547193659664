.react-calendar {
    width: 100%;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: 'Arial', 'Helvetica', sans-serif;
    line-height: 1.125em;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    font: inherit;
    font-size: 0.833em;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__tile--now {
    background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
}

.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar__tile--active {
    background: #006edc;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.win-loss-modal {
    width: 400px
}

@media (max-width: 600px) {
    .win-loss-modal {
        width: 90%;
        /* Adjust width for smaller screens */
        padding: 10px;
        /* Reduce padding for smaller screens */
    }
}

.win-loss-list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.win-list,
.loss-list {
    width: 45%;
}

.win-item,
.loss-item {
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    font-weight: bold;
}

.win-item {
    background-color: #d4edda;
    color: #155724;
}

.loss-item {
    background-color: #f8d7da;
    color: #721c24;
}

.win-loss-container {
    display: flex;
    justify-content: space-between;
}

.win-section,
.loss-section {
    width: 45%;
}

.win-tag,
.loss-tag {
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    cursor: pointer;
}

.win-tag {
    background-color: #d4edda;
    color: #155724;
}

.loss-tag {
    background-color: #f8d7da;
    color: #721c24;
}

.point-summation {
    margin-top: 20px;
    text-align: center;
}

/* --------- DROPDOWN ------------ */
.custom-dropdown {
    margin-bottom: 20px;
}

.custom-dropdown label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.custom-dropdown select {
    width: 100%;
    padding: 10px;
    border-radius: 0px;
    border: none;
    background-color: rgb(3, 27, 78);
    color: white;
    font-size: 15px;
    font-weight: bold;
    box-shadow: none;
    text-decoration: none;
}