
  .styled-input {
    width: 95%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 4px;
    color: #333;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  
    &:hover {
      border-color: rgb(3, 27, 78);
    }
  
    &:focus {
      border: 2px dotted rgb(3, 27, 78);
      outline: 1px solid rgb(3, 27, 78);
    }
  }