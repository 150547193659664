.win-loss-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
}

.win-loss-form h3 {
    margin-bottom: 20px;
    color: rgb(3, 27, 78);
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.custom-button {
    background-color: rgb(3, 27, 78);
    border: none;
    color: white;
    text-decoration: none;
    font-size: 15px;
    box-shadow: none;
    border-radius: 0px;
    padding: 10px 10px 10px 10px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.custom-button:hover {
    background-color: rgb(2, 20, 58);
}