.App-header {
  /* background-color: #282c34; */
  margin-top: 10px;
  min-height: 50vh;
  /* align-items: center;
  justify-content: center; */
  margin: auto;
  /* color: white; */

}

.search-bar-container {
  display: grid;
  justify-content: center;
  margin-top: 10px;
  font-size: small;
  color: gray
}

.login-section {
  display: grid;
  justify-content: center;

  margin-top: 10px;
  font-size: small;
}

.sign-in-heading {
  text-align: center;

}

/*make menu section collapse instead of flex if it goes less than 377 px .menu-section*/
@media screen and (min-width: 377px) {
  .menu-section {
    display: flex;
    justify-content: center;

  }
}

.menu-section {
  justify-content: center;
  margin-top: 10px;
  font-size: small;
  color: gray
}

canvas {
  /* background-image: url("../public/night") */
}